.card {
  min-height: 720px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px auto;
  width: 80%;
}

.menu button {
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem;
}

.menu button:hover {
  background: #ccc;
  border: 2px solid #109352;
}

.result-text {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.result-word {
  background: #ccc;
  border: 5px solid transparent;
  border-image: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
  margin: 1rem;
  padding: 0.5rem;
}

.result-word p {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.keyboard-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.keyboard-row {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.keyboard-button {
  border: 1px solid #ccc; 
  height: 30px;
  user-select: none;
  width: 30px;
}

.keyboard-button.correct {
  background: green !important;
  color: white;
}

.keyboard-button:hover {
  background: black;
  color: white;
}

.keyboard-button:disabled {
  background: grey;
  color: white;
}

.drawing-head {
  position: absolute;
  height: 50px;
  width: 50px;
  border: 10px solid black;
  border-radius: 50%;
  top: 50px;
  left: 290px;
}

.drawing-body {
  border-radius: 0 0 8px 8px;
  position: absolute;
  height: 100px;
  width: 10px;
  background: Black;
  top: 90px;
  left: 310px;
}

.drawing-left-arm {
  position: absolute;
  height: 10px;
  width: 60px;
  background: Black;
  top: 110px;
  left: 260px;
}

.drawing-right-arm {
  position: absolute;
  height: 10px;
  width: 60px;
  background: Black;
  top: 110px;
  left: 310px;
}

.drawing-left-leg {
  position: absolute;
  height: 10px;
  width: 80px;
  background: Black;
  top: 210px;
  left: 245px;
  transform: rotate(315deg);
}

.drawing-right-leg {
  position: absolute;
  height: 10px;
  width: 80px;
  background: Black;
  top: 210px;
  left: 305px;
  transform: rotate(45deg);
}

.drawing-rope {
  position: absolute;
  height: 55px;
  width: 10px;
  background: Black;
  left: 310px;
}

.drawing-topbar {
  height: 10px;
  width: 150px;
  background: Black;
  margin-left: 170px
}

.drawing-basebar {
  height: 400px;
  width: 10px;
  background: Black;
  margin-left: 170px;
}

.drawing-foot {
  height: 10px;
  width: 250px;
  background: Black;
  margin-left: 80px;
}

@media only screen and (max-width: 600px) {
  .card {
    min-height: 0;
  }  

  .drawing-head {
    left: 225px;
  }
  
  .drawing-body {
    left: 245px;
  }
  
  .drawing-left-arm {
    left: 195px;
  }
  
  .drawing-right-arm {
    left: 245px;
  }
  
  .drawing-left-leg {
    left: 180px;
  }
  
  .drawing-right-leg {
    left: 240px;
  }
  .drawing-rope {
    left: 245px;
  }
  
  .drawing-topbar {
    margin-left: 125px;
    width: 130px;
  }

  .drawing-basebar {
    height: 280px;
    margin-left: 125px;
  }

  .drawing-foot {
    margin-left: 35px;
    width: 230px;
  }
}