@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Nunito', 'sans-serif';
  font-size: 16px;
}

body {
  background: #090909;
}

button,
input {
  font-family: 'Nunito', 'sans-serif';
}

h1 {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

#root {
  height: 100vh;
  background: radial-gradient(ellipse at left, #202020 0%, #090909 100%);
  overflow: hidden;
}

.logo {
  margin: 0 16px;
}

.logo:hover {
  fill: #109352;
}

.nav-bar {
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(255, 255, 255, .04);
  border-bottom: 1px solid rgba(255, 255, 255, .09);
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin-top: -26px;
}

.nav-bar-items {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;

}

.nav-bar .divider {
  background: #404040;
  border: none;
  height: 32px;
  width: 1px;
}

.nav-bar .nav-button {
  border-radius: 2px;
  padding: 4px 12px;
}

.nav-bar .nav-button:hover {
  background: #404040;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito', 'sans-serif';
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
}

.welcome-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

.job-title {
  color: white;
  margin-bottom: 1rem;
}

.content {
  padding: 0 1rem;
  max-width: 500px;
  min-height: 40vh;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.card-header {
  flex: 1 1 auto;
  max-height: 80px;
  padding: 1.25rem 1.25rem 0 1.25rem;
}

.card-title {
  font-size: 2rem;
  margin: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.switch-account {
  color: white;
  display: flex;
  justify-content: center;
}

.btn {
  border-radius: 0.25rem;
  display: block;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 0.375rem 0.75rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
}

.btn:hover {
  cursor: pointer;
}

.btn-transparant {
  background-color:#fff;
  border: 1px solid transparent;
  color: #000;
}

.btn-transparant:hover {
  background-color: #efefef;
}

.btn-blue {
  background-color: #109352;
  color: #fff;
}

.btn-blue:hover {
  background-color: #096c3b;
}

.alert-box {
  background-color: #f8d7da;
  border: 1px solid transparent;
  border-color: #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
  margin-top: 1.25rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.message-box {
  background-color: #d4edda;
  border: 1px solid transparent;
  border-color: #c3e6cb;
  border-radius: 0.25rem;
  color: #155724;
  margin-top: 1.25rem;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.authorization-form {
  display: flex;
  flex-direction: column;
}

.authorization-form label {
  margin: 0.25rem 0;
}

.authorization-form input {
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
}

.forgot-password {
  margin-top: 1rem;
  text-align: center;
}

.switch-account {
  margin-top: 1rem;
  text-align: center;
}

.link {
  color: #109352;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.s-stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: var(--smallStars);
  animation: glideStars 50s linear infinite;
}

.s-stars::after {
  content: " ";
  position: absolute;
  left: 1000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: var(--smallStars);
}

.m-stars {
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: var(--mediumStars);
  animation: glideStars 70s linear infinite;
}

.m-stars::after {
  content: " ";
  position: absolute;
  left: 1000px;
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: var(--mediumStars);
}
    
.l-stars {
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 50%;
  box-shadow: var(--largeStars);
  animation: glideStars 100s linear infinite;
}

.l-stars::after {
  content: " ";
  position: absolute;
  left: 1000px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: var(--largeStars);
}

.death-star {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 240px;
  left: 600px;
  background-color: #808080;
  animation: glideStars 150s linear infinite;
}

.death-star .shadow {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 1px;
  background-color: #323232;
}

.death-star .circle{
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  top: 2px;
  left: 11px;
  background-color: #212121;
}

.death-star .trait{
  position: absolute;
  background: #212121;
  width: 105%;
  height: 1px;
  top: 10px;
  background-color: #212121;
}

.death-star:hover {
  cursor: pointer;
}

@keyframes glideStars {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-1000px);
  }
}

@media(min-width: 600px){
  .s-stars::after, .m-stars::after, .l-stars::after {
    left: 2000px;
  }

  .death-star {
    left: 2000px;
  }

  @keyframes glideStars {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-2000px);
    }
  }

}

@media(min-width: 2000px){
  .s-stars::after,
  .m-stars::after,
  .l-stars::after {
    left: 4000px;
  }

  .death-star {
    left: 4000px;
  }

  @keyframes glideStars {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-4000px);
    }
  } 
}